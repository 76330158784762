<template>
	<div id="userInfo">
		<div class="el-content">
			<a-form ref="form" label-width="80px">
				<a-tabs v-model:activeKey="activeName">
					<a-tab-pane tab="基本信息" key="1">
						<div class="kd-user-li">
							<div class="kd-user-li-title">头像</div>
							<div class="kd-user-li-head">
								<img class="kd-pic-url" v-if="info.pic_url" :src="info.pic_url" alt="">
								<img class="kd-pic-url" v-else  src="@/assets/img/moren.png" alt="">
							</div>
						</div>
						<div class="kd-user-li">
							<div class="kd-user-li-title">用户</div>
							<div class="kd-user-li-head">
								<span>{{info.name}}</span>
							</div>
						</div>
						
						<div class="kd-user-li">
							<div class="kd-user-li-title">余额</div>
							<div class="kd-user-li-head">
								<span>{{bank.bank}}元</span>
								<router-link to="/many_store/fund/withdraw">
									<span style="color: #0074D9;cursor: pointer;">[去提现]</span>
								</router-link>
							</div>
						</div>
						
						<div class="kd-user-li">
							<div class="kd-user-li-title">手机号</div>
							<div class="kd-user-li-head">
								<span>{{info.mobile}}</span>
							</div>
						</div>
						<div class="kd-user-li">
							<div class="kd-user-li-title">密码</div>
							<div class="kd-user-li-head">
								<span>******</span>
							</div>
							<div class="kd-user-li-opration">
								<a-button   @click="passwordDialog=true" type="link">修改</a-button>
							</div>
						</div>
						
						<div class="kd-user-li">
							<div class="kd-user-li-title">最后登录时间</div>
							<div class="kd-user-li-head">
								<span>{{info.login_time}}</span>
							</div>
						</div>
						<div class="kd-user-li">
							<div class="kd-user-li-title">最后登录IP</div>
							<div class="kd-user-li-head">
								<span>{{info.last_login_ip}}</span>
							</div>
						</div>
					</a-tab-pane>
					<a-tab-pane tab="店铺信息" key="2">
						<div class="kd-user-li">
							<div class="kd-user-li-title">LOGO</div>
							<div class="kd-user-li-head">
								<img class="kd-pic-url" v-if="info.shop.logo" :src="info.shop.logo" alt="">
								<img class="kd-pic-url" v-else src="@/assets/img/moren.png" alt="">
							</div>
							<div class="kd-user-li-opration">
								<kd-img-select :custom="true" @change="e=> info.shop.logo = e">
									<a-button  type="link">修改</a-button>
								</kd-img-select>
							</div>
						</div>
						
						<div class="kd-user-li">
							<div class="kd-user-li-title">店铺名称</div>
							<div class="kd-user-li-head">
								<a-input  v-if="update_type==2" v-model:value="info.shop.name"></a-input>
								<span v-else>{{info.shop.name}}</span>
							</div>
							<div class="kd-user-li-opration">
								<a-button  @click="update_type=2" type="link">修改</a-button>
							</div>
						</div>
					
						<div class="kd-user-li">
							<div class="kd-user-li-title">店铺电话</div>
							<div class="kd-user-li-head">
								<a-input  v-if="update_type==3" v-model:value="info.shop.mobile"></a-input>
								<span v-else>{{info.shop.mobile}}</span>
							</div>
							<div class="kd-user-li-opration">
								<a-button  @click="update_type=3" type="link">修改</a-button>
							</div>
						</div>
						<div class="kd-user-li" style="height: 260px;">
							<div class="kd-user-li-title">订单通知配置</div>
							<div class="kd-user-li-head" >
								<div>
									<a-button type="primary" @click="getWxAuthOpendid">获取授权二维码</a-button>
								</div>
								
								<a-image style="width: 150px;height: 150px;" v-if="wxQrcode" :src="wxQrcode"></a-image>
								<p style="line-height: 20px;margin: 0;">注意：请先获取授权二维码，再使用微信扫码上方二维码，并授权关注公众号即可收到订单消息通知！</p>
							</div>
							
						</div>
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">店铺地址</div>
							<div class="kd-user-li-head">
								<div v-if="update_type==4">
									<a-input v-model:value="info.shop.address"></a-input>
									<kd-map :value="info.shop.longitude+','+info.shop.latitude" @change="selectAddress"></kd-map>
									<!-- <div class="map-input">
										<a-input  v-model="info.shop.latitude"></a-input>
										<a-input  v-model="info.shop.longitude"></a-input>
										<a-button @click="mapShow=true" style="height: 38px;margin-top: 5px;"> 选择地址 </a-button>
									</div> -->
									<!-- <kd-map :map-lnglat="[info.shop.longitude,info.shop.latitude]" @selectAddress="selectAddress"></kd-map> -->
								</div>
								<span v-else>{{info.shop.address}}</span>
							</div>
							<div class="kd-user-li-opration">
								<a-button  @click="update_type=4" type="link">修改</a-button>
							</div>
						</div>
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">店铺简介</div>
							<div class="kd-user-li-head" style="padding: 10px 0;">
								<a-input v-if="update_type == 5" type="textarea" v-model:value="info.shop.description"></a-input>
								<span v-else>{{info.shop.description}}</span>
							</div>
							<div class="kd-user-li-opration">
								<a-button  @click="update_type=5" type="link">修改</a-button>
							</div>
						</div>
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">标签</div>
							<div class="kd-user-li-head">
								<a-tag style="margin-right: 10px;" v-for="(item,index) in info.shop.tag" :key="index" closable :disable-transitions="false" @close="handleClose(item)">
								  {{item}}
								</a-tag>
								<a-input class="input-new-tag" v-if="inputTagVisible"  v-model:value="inputTag" ref="saveTagInput" 
									@blur="handleInputConfirm" >
								</a-input>
								<a-button v-else class="button-new-tag"  @click="showInput">+ 新增标签</a-button>
							</div>
						</div>
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">图片展示</div>
							<div class="kd-user-li-head" style="padding-top: 10px;">
								<div v-if="update_type==6">
									<kd-img-select :mutil="true" :src="info.shop.images" @change="(url)=>{ info.shop.images = url }"></kd-img-select>
								</div>
								<a-image  v-else  class="kd-user-li-head-logo" v-for="(item,index) in info.shop.images" :key="index" :src="item"> </a-image>
							</div>
							<div class="kd-user-li-opration">
								<a-button  @click="update_type=6" type="link">修改</a-button>
							</div>
						</div>
						
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">营业执照</div>
							<div class="kd-user-li-head" style="padding-top: 10px;">
								<a-image class="kd-user-li-head-logo" :src="info.shop.card_img" ></a-image>
								<a-image class="kd-user-li-head-logo" :src="info.shop.business_img"></a-image>
							</div>
						</div>
						<div class="kd-user-li" style="height: auto;">
							<div class="kd-user-li-title">店铺小程序码</div>
							<div class="kd-user-li-head" style="padding-top: 10px;">
								<a-image class="kd-user-li-head-logo" :src="info.shop.qrcode"></a-image>
							</div>
						</div>
						<div class="kd-user-li">
							<div class="kd-user-li-title">店铺创建时间</div>
							<div class="kd-user-li-head" >
								<span>{{info.shop.create_time}}</span>
							</div>
						</div>
						
					</a-tab-pane>
				</a-tabs>
				<a-form-item>
					<a-button type="primary" @click="updateShopInfo">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-form-item>	
			</a-form>

			<a-modal v-model:visible="passwordDialog" title="修改密码" @ok="savePassWord" @cancel="passwordDialog = false">
				<a-form ref="form" :model="passwordForm" :label-col="{span:4}" :wrapper-col="{span:16}">
					<a-form-item label="新密码">
					    <a-input v-model:value="passwordForm.password"></a-input>
					</a-form-item>
					<a-form-item label="再次新密码">
					    <a-input v-model:value="passwordForm.re_password"></a-input>
					</a-form-item>
				</a-form>
			</a-modal>
		</div>
	</div>

</template>

<script>	
import kdMap from '@/components/public/kd-map.vue'
import { reactive, toRefs } from 'vue'
import shopModel from '@/api/addons/shop'
export default{
	components:{
		kdMap
	},
	setup(){
		const state = reactive({
			activeName:'1',
			bank:{},
			info:{shop:{logo:''}},
			update_type:0,		//1 店铺logo
			passwordDialog:false,
			passwordForm:{
				password:'',
				re_password:''
			},
			mapShow:false,
			imageVisible:false,
			inputTag:'',
			inputTagVisible:false,
			wxQrcode:'',		//微信授权二维码
		})
		
		shopModel.getShopUserDetail(res=>state.info = res)
		shopModel.getGlod(res=>state.bank = res)

		const savePassWord = ()=>shopModel.updateShopUserPwd(state.passwordForm)
		function updateShop(){
			state.update_type = e
			state.imageVisible = true
		}
		function updateShopInfo(){
			let { shop } = state.info
			let data = {
				tag:shop.tag,
				logo:shop.logo,
				name:shop.name,
				mobile:shop.mobile,
				images:shop.images,
				shop_id:shop.shop_id,
				address:shop.address,
				latitude:shop.latitude,
				longitude:shop.longitude,
				description:shop.description,
			}

			shopModel.addOrEditShop(data,false)
		}

		//选择地址
		function selectAddress(e){
			this.info.shop.latitude = e.latitude
			this.info.shop.longitude = e.longitude
		}

		function handleClose(tag) {
			state.info.shop.tag.splice(state.info.shop.tag.indexOf(tag), 1);
		}
		function showInput() {
			state.inputTagVisible = true;
		}

		function handleInputConfirm(){
			let {inputTag} = state;
			if (inputTag) {
				state.info.shop.tag.push(inputTag);
			}
			state.inputTagVisible = false;
			state.inputTag = '';
		}

		const getWxAuthOpendid = ()=>shopModel.getWxAuthOpendid(state.info.shop.shop_id,res=>state.wxQrcode = res)
		return{
			...toRefs(state),
			savePassWord,
			updateShop,
			selectAddress,
			updateShopInfo,
			handleClose,
			showInput,
			handleInputConfirm,
			getWxAuthOpendid
		}
	}
}
</script>

<style lang="scss">
	.kd-user-li{
		width: 100%;
		height: 50px;
		line-height: 50px;
		display: flex;
		font-size: 14px;
		color: #868686;
		border-bottom: 1px solid #f1eded;
		
		&-title{
			width: 10%;
		}
		&-head{
			width: 50%;
			
			&-logo{
				width: 80px;
				height: 80px;
				margin-right: 10px;
			}
		}
		&-opration{
			width: 40%;
			text-align: right;
			color: #0074D9;
			cursor: pointer;
		}
	}
	.kd-pic-url{
		width: 50px;
		height: 50px;
		border-radius: 50%;
	}
</style>
